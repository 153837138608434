import React, { useState } from "react";

import {
  BriefcaseMedical,
  BusFront,
  ChevronDown,
  ChevronUp,
  Dumbbell,
  FerrisWheel,
  GraduationCap,
  Hospital,
  Landmark,
  Plane,
  ShoppingBag,
  ShoppingCart,
  TrainFront,
  TramFront,
  LocateFixed,
} from "lucide-react";
import MapComponent from "./mapComponent";

export default function LocationGrid() {
  const [neighbourType, setNeighbourType] = useState("landmarks");
  const [destination, setDestination] = useState("");
  const [openDropdown, setOpenDropdown] = useState("attractions");

  const railwayStations = {
    "Chennai Egmore Railway Station 5 km | 20 mins":
      "13.077602226408532, 80.26164481573386",
    "Puratchi Thalaivar Dr. M.G. Ramachandran Central Railway Station 5 km | 20 mins":
      "13.082367775712422, 80.27621741337254",
  };

  const airports = {
    "Chennai Airport 15 km | 45 mins": "12.993856148736183, 80.170644958676",
  };
  const hospitals = {
    "Appollo Main Hospital 1 km | 3 mins": "13.062775924678, 80.25188043030455",
    "Sankara Nethralaya Hospital 2 km | 5 mins":
      "13.066905410967589, 80.25067292853898",
    "Dr Mohan's Diabetic Hostpital 2 km | 5 mins": "",
    // "Government Royapettah Hospital 2.1 km | 10 mins":
    //   "13.055197777315101, 80.26460899373522",
    // "Genesis Women's Hospital 3.5 km | 15 mins": "",
  };

  const attractions = {
    "US Consulate, Chennai 0.5 km | 1 mins":
      "13.052469467959064, 80.25190245664804",
    "US Visa OFC/ VAC for Fingerprint Biometric 2 km | 10 mins":
      "13.053171971228398, 80.24311693678784",
    // "Thousand Lights Mosque 1.2 km | 2 mins":
    //   "13.054932639529257, 80.25503125349199",
    // "Lalit Kala Akademi 1.5 km | 3 mins":
    //   "13.064893848527726, 80.25480928738625",
    // "Amir Mahal 2.1 km | 5 mins": "13.056812212281026, 80.26644052134611",
  };

  const shoppingMall = {
    // "Bergamo Luxury Mall 0.9 km | 2 mins":
    //   "13.06090697049079, 80.25118704501305",
    "Spencer Plaza 1.3 km | 5 mins": "13.061439401179284, 80.26129552271846",
    "Express Avenue Mall 2.1 km | 14 mins":
      "13.058469554629278, 80.2641884566911",
    "Ramee Mall 2 km | 10 mins": "13.042997446781724, 80.2482736116103",
    // "D Mart 7.1 km | 22 mins": "",
  };
  const colleges = {
    "Loyola College 3 km | 15 mins": "13.062790032076267, 80.23350164896428",
    "Stella Maris College 2 km | 5 mins":
      "13.047661364303677, 80.25402449656424",
    "Queen Mary's College 4 km | 10 mins":
      "13.044655368122195, 80.27905966690423",
    "Quaid-E-Millath Government College 2 km | 10 mins":
      "13.063234009176885, 80.26215205025869",
    "MOP Vaishna College 0.5 km | 5 mins":
      "13.05553632295857, 80.25037559090343",
    // "Presidency College Chennai 7.1 km | 22 mins": "",
  };
  const metroStation = {
    "Thousand lights Metro 0.5 km | 5 min":
      "13.058061486755545, 80.25822405265926",
  };

  const busStations = {
    "Thousand lights Bus Station 0.5 km | 5 min":
      "13.058061486755545, 80.25822405265926",
  };

  return (
    <div className="flex flex-col gap-10 p-2 sm:p-10 w-full shadow-2xl shadow-slate-400 bg-white bg-opacity-40 border-x-2 border-b-2 rounded-md   ">
      <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans text-">
        Explore Neighborhood
      </h2>

      <div className="container flex flex-col lg:flex-row gap-5 w-full min-h-[30rem] ">
        {/* <MapComponent/> */}
        <div className="map-left flex flex-col gap-2 w-full lg:w-2/3 h-full">
          <MapComponent destination={destination} />
        </div>
        <div className="details-right w-full lg:w-1/3  h-full text-black text-[14px] sm:text-[16px]">
          <div className="header grid grid-cols-3 bg-gradient-to-r from-[#385c54] to-[#6d9990c9] font-merriweatherSans">
            <div
              className={` p-2 sm:px-6 cursor-pointer hover:font-500 ${
                neighbourType == "landmarks"
                  ? "border-b-4 border-slate-300"
                  : ""
              }`}
              onClick={() => setNeighbourType("landmarks")}
            >
              Landmarks
            </div>
            <div
              className={` p-2 sm:px-6 cursor-pointer hover:font-500 ${
                neighbourType == "transport"
                  ? "border-b-4 border-slate-300"
                  : ""
              }`}
              onClick={() => setNeighbourType("transport")}
            >
              Transport
            </div>
            <div
              className={` p-2 sm:px-6 cursor-pointer hover:font-500 ${
                neighbourType == "essentials"
                  ? "border-b-4 border-slate-300"
                  : ""
              }`}
              onClick={() => setNeighbourType("essentials")}
            >
              Essentials
            </div>
          </div>

          <div className="content flex flex-col gap-3 bg-[#dbe8e6b8] h-full w-full p-2 sm:p-4 sn:px-5">
            {neighbourType == "transport" ? (
              <>
                <Dropdown
                  title="Railway Station"
                  items={railwayStations}
                  logo="TramFront"
                  isOpen={openDropdown == "Railway Station"}
                  onDropdownClick={() => setOpenDropdown("Railway Station")}
                  onItemClick={(location) => setDestination(location)}
                />
                <Dropdown
                  title="Bus Station"
                  items={busStations}
                  logo="BusFront"
                  isOpen={openDropdown == "Bus Station"}
                  onDropdownClick={() => setOpenDropdown("Bus Station")}
                  onItemClick={(location) => setDestination(location)}
                />
                <Dropdown
                  title="Metro Station"
                  items={metroStation}
                  logo="TrainFront"
                  isOpen={openDropdown == "Metro Station"}
                  onDropdownClick={() => setOpenDropdown("Metro Station")}
                  onItemClick={(location) => setDestination(location)}
                />
                <Dropdown
                  title="Airport"
                  items={airports}
                  logo="Plane"
                  isOpen={openDropdown == "Airport"}
                  onDropdownClick={() => setOpenDropdown("Airport")}
                  onItemClick={(location) => setDestination(location)}
                />
              </>
            ) : neighbourType == "essentials" ? (
              <>
                <Dropdown
                  title="Hospitals"
                  items={hospitals}
                  logo="Hospital"
                  isOpen={openDropdown == "Hospitals"}
                  onDropdownClick={() => setOpenDropdown("Hospitals")}
                  onItemClick={(location) => setDestination(location)}
                />
                <Dropdown
                  title="Colleges"
                  items={colleges}
                  logo="GraduationCap"
                  isOpen={openDropdown == "Colleges"}
                  onDropdownClick={() => setOpenDropdown("Colleges")}
                  onItemClick={(location) => setDestination(location)}
                />
              </>
            ) : neighbourType == "landmarks" ? (
              <>
                <Dropdown
                  title="Prime Facilities"
                  items={attractions}
                  logo="LocateFixed"
                  isOpen={openDropdown == "attractions"}
                  onDropdownClick={() => setOpenDropdown("attractions")}
                  onItemClick={(location) => setDestination(location)}
                />
                <Dropdown
                  title="Shopping Mall"
                  items={shoppingMall}
                  logo="ShoppingBag"
                  isOpen={openDropdown == "Shopping Mall"}
                  onDropdownClick={() => setOpenDropdown("Shopping Mall")}
                  onItemClick={(location) => setDestination(location)}
                />
                {/* <Dropdown
                  title="Gym"
                  items={superMarket}
                  logo="Dumbbell"
                  isOpen={openDropdown == "Gym"}
                  onDropdownClick={() => setOpenDropdown("Gym")}
                />
                <Dropdown
                  title="Park"
                  items={shoppingMall}
                  logo="FerrisWheel"
                  isOpen={openDropdown == "Park"}
                  onDropdownClick={() => setOpenDropdown("Park")}
                /> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Dropdown = ({
  title,
  items,
  logo,
  isOpen,
  onDropdownClick,
  onItemClick,
}) => {
  const logoMapping = {
    BusFront,
    TrainFront,
    TramFront,
    Plane,

    Hospital,
    BriefcaseMedical,
    GraduationCap,
    Landmark,

    ShoppingCart,
    ShoppingBag,
    Dumbbell,
    FerrisWheel,
    LocateFixed,
  };
  const IconComponent = logoMapping[logo];

  return (
    <div className="borde border-gray-200 rounded-lg shadow-sm bg-[#397267d1]">
      <button
        className="w-full flex justify-between items-center p-2"
        onClick={onDropdownClick}
      >
        <div className="flex gap-3">
          {/* Render the icon component dynamically */}
          {IconComponent && <IconComponent />}
          <span className="font-medium">{title}</span>
        </div>
        <span>{isOpen ? <ChevronUp /> : <ChevronDown />}</span>
      </button>
      {isOpen && (
        <ul className="mt-2 bg-[#93c1b783]">
          {Object.entries(items)?.map((item, index) => (
            <li
              key={index}
              className="px-4 py-2 border-b last:border-none hover:font-semibold cursor-pointer"
              onClick={() => onItemClick(item[1])}
            >
              {item[0]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
