import React, { useRef } from "react";
import { MdOutlineErrorOutline } from "react-icons/md";

function FailedModal({ closeModal, text }) {
  // const modalRef = useRef();
  // const bgModal = (e) => {
  //   if (modalRef.current == e.target) {
  //     closeModal();
  //   }
  // };
  return (
    <div
      className="fixed top-0 left-[calc(50vw- 100px)] sm:left-[calc(50vw-10rem)] transform -translate-x-10 p-3 sm:p-5  flex flex-col items-center gap-5 bg-gradient-to-r from-[#2d2b2eea] to-[#5b3939ea]  rounded-xl"
      data-aos="fade-down"
    >
      <MdOutlineErrorOutline size={50} className="text-red-500 " />
      <p className="text-center text-[12px] sm:text-[16px] font-mono">
        Network error occurred. Try again.
      </p>
    </div>
  );
}

export default FailedModal;
