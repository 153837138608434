import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";

export default function FeaturesGrid({ featureList }) {
  return (
    <div className="flex flex-col gap-5 w-full">
      {Object.entries(featureList)?.map(([type, features], index) => (
        <div className="flex flex-col items-start w-full" key={index}>
          <div className="font-bold text-[16px] sm:text-[18px]">{type}</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-start items-start w-full h-full ps-3">
            {features?.map((items, index) => (
              <div
                className="flex gap-2 items-center"
                key={index}
              >
                <FaRegCircleCheck size={15} className="text-[#8ab6ad] w-7 sm:w-10" />
                <span className=" font-forum text-[14px] sm:text-[18px]">{items}</span>
              </div>
            ))}
          </div>
          <div>
            {/* <span className="text-[16px] font-500"></span> */}
            {/* <p className="text-[14px] fot-300 text-white">{items}</p> */}
          </div>
        </div>
      ))}
    </div>
  );
}
