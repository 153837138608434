import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { TbCurrentLocation } from "react-icons/tb";
import location from "../assets/location.webp";
import { FiNavigation } from "react-icons/fi";

const containerStyle = {
  width: "100%",
  height: "30rem",
};

const defaultCenter = { lat: 13.054498213053312, lng: 80.25305762966455 };

const MapComponent = ({ destination }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    // id: "google-map-script",
    libraries: ["places"],
    googleMapsApiKey: "AIzaSyDw7FUMu2LEyrh9qBt7MwhnO4USGdm6PO8",
  });

  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [directionResponse, setdirectionResponse] = useState(null);

  /**@type React.MutableRefObject<HTMLInputElement> */
  // const originRef = useRef();
  /**@type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef(null);

  useEffect(() => {
    if (isLoaded && destinationRef.current && destination) {
      destinationRef.current.value = destination;
      calculateRoute();
    }
  }, [destination, isLoaded]);

  async function calculateRoute() {
    //eslint-disable-next-line no-undef
    if (!google || !google.maps) {
      console.error("Google Maps is not loaded.");
      return;
    }

    if (destinationRef.current?.value == "" && !destination) return;

    //eslint-disable-next-line no-undef
    const directionService = new google.maps.DirectionsService();
    const results = await directionService.route({
      origin:
        "HOTEL CRYSTAL PARK, 51/22, Model School Rd, OPP. U.S CONSULATE, Thousand Lights West, Thousand Lights, Chennai, Tamil Nadu 600006",
      destination: destinationRef.current.value || destination,
      //eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setdirectionResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  useEffect(() => {
    console.log("directionResponse:", directionResponse);
  }, [directionResponse]);

  function clearRoute() {
    setdirectionResponse(null);
    setDistance("");
    setDuration("");
    destinationRef.current.value = "";
    // calculateRoute();
  }

  const navigateToGoogleMaps = () => {
    console.log("triggered...");

    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      "Hotel Crystal Park, Chennai"
    )}`;
    window.open(url, "_blank");
  };

  const onLoad = useCallback((map) => {
    setMap(map);
    const marker = new window.google.maps.Marker({
      position: defaultCenter,
      map: map,
    });
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="address-bar flex flex-col md:flex-row justify-between text-[14px] sm:text-[16px] pb-2">
        <div className="flex gap-2 items-center">
          <div className="w-5 sm:w-7 overflow-hidden">
            <img src={location} alt="hotel-crystal-park-chennai-location" className="object-cover" />
          </div>
          <span>
            Hotel Crystal Park, opp. to US Consulate, Chennai - 600006
          </span>
        </div>
        <div className="relative flex flex-col sm:flex-row gap-2 items-center w-full">
          <div className="flex gap-1 w-full">
            <label>To:</label>
            <div className="flex flex-col gap-1 w-full overflow-hidden">
              <Autocomplete>
                <input
                  type="text"
                  ref={destinationRef}
                  className="border-2 p-1 w-full h-full outline-none rounded-md"
                />
              </Autocomplete>
              <div className="sm:absolute top-full z-10 flex flex-col sm:flex-row gap-1 w-full text-[12px] sm:text-[14px]">
                {distance && (
                  <span className="flex gap-1 justify-between items-center p-1 px-2 text-white bg-[#1b4940b8] rounded-md">
                    Distance:{" "}
                    <span className=" p-1 px-2 bg-[#1e4a41] rounded-md  whitespace-nowrap">
                      {distance}
                    </span>
                  </span>
                )}
                {duration && (
                  <span className="flex gap-1 justify-between items-center p-1 px-2 text-white bg-[#1b4940b8] rounded-md ">
                    Duration:{" "}
                    <span className=" p-1 px-2 bg-[#1e4a41] rounded-md whitespace-nowrap">
                      {duration}
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-2 ">
            <button
              onClick={calculateRoute}
              className="p-1 sm:p-2 px-5 sm:px-10 text-white bg-[#1e4a41] rounded-md"
            >
              Search
            </button>

            {directionResponse && (
              <button
                onClick={clearRoute}
                className="p-1 sm:p-2 px-5 sm:px-10 text-white bg-[#1e4a41] rounded-md"
              >
                Clear
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="relative">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={18}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker position={defaultCenter} />
          <Marker
            position={{ lat: 13.053058620104638, lng: 80.25220161383174 }}
          />
          {directionResponse && (
            <DirectionsRenderer directions={directionResponse} />
          )}
        </GoogleMap>

        <div
          className="absolute right-3 top-[50%] cursor-pointer p-1 bg-white bg-opacity- rounded-full"
          onClick={() => {
            map.panTo(defaultCenter);
            map.setZoom(18);
          }}
        >
          <TbCurrentLocation size={25} className="hover:scale-105" />
        </div>
        <div className="absolute right-3 top-[40%] cursor-pointer p-1 bg-white bg-opacity- rounded-full">
          <FiNavigation
            size={25}
            className="hover:scale-105 z-10"
            onClick={navigateToGoogleMaps}
          />
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
