import React, { useRef } from "react";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";

// Components
import Suites from "../components/hotelBanner";
import ReviewCarousel from "../components/reviewGrid";
import LocationGrid from "../components/locationGrid";
import PropertyOverview from "../components/propertyOverview";
import FeaturesGrid from "../components/featuresGrid";

// Images
import deluxe1 from "../assets/deluxe/1.webp";
import deluxe2 from "../assets/deluxe/2.webp";
import executive1 from "../assets/executive/1.webp";
import executive2 from "../assets/executive/4.webp";
import suite1 from "../assets/suite/1.webp";

// Icons
import { Rating } from "@mui/material";
import { PiCoffeeBold, PiWifiMediumBold } from "react-icons/pi";
import { MdLocationPin, MdOutlineBedroomParent } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { LuBedDouble } from "react-icons/lu";
import { Link } from "react-scroll";
import { FaPersonCirclePlus } from "react-icons/fa6";

export default function Homepage() {
  const navigate = useNavigate();

  // const targetRef = useRef(null);

  const featureList = {
    "ID Proof Related": [
      "Local IDs not allowed",
      "Valid Photo ID is a must",
      "Unmaried Couples not allowed",
      // "Passport, Aadhar, Driving License and Govt. ID's accepted",
    ],
    "Food Arrangements": [
      "Outside food is allowed",
      // "Non veg food is allowed",
      // "In room dining not available",
      // "Food Delivery is available",
    ],
    "Pet(s) Related": [
      "Pets are not allowed",
      // "There are no pets living on the property",
    ],
    // "Smoking/Alcohol consumption Rules": [
    //   "only NOT allowed",
    //   "Alcohol not allowed within hotel premises",
    //   "Smoking within the premises is not allowed",
    // ],
    // "Amenities at Hotel Crystal Park": [
    //   "Sanitizers",
    //   "Glasses",
    //   "TV",
    // "Umbrella",
    // ],
    "Safety and Security": ["CCTV", "Fire Extinguishers"],
    Others: ["Smoking not allowed", "Alcohol not allowed"],
  };

  return (
    <div className="w-full h-full">
      {/* First Section */}
      <div className="w-full h-screen max-h-screen overflow-hidden bg-black flex flex-col">
        <div className="overflow-hidden w-full h-full">
          <div
            className="flex items-center justify-center h-full  bg-cover bg-[center_60%] text-black overflow-hidden"
            // style={{ backgroundImage: `url(/assets/2.jpg)` }}
          >
            <div className="flex flex-col gap-5 sm:p-3 rounded-lg font-cinzel text-center w-full h-full">
              <div className="flex flex-col md:flex-row gap-1 sm:gap-3 w-full h-full">
                <div
                  className="relative sm:h-full h-1/4 md:w-3/4 bg-cover bg-center bg-no-repeat backdrop-blur-sm"
                  // style={{ backgroundImage: `url(/1.jpg)` }}
                >
                  <div className="absolute flex gap-3 inset-0 w-full h-full ">
                    <div className="w-4/12 grid grid-rows-2 gap-1 sm:gap-3 overflow-hidden">
                      <img
                        src="/statue.webp"
                        className="w-full h-full aspect-square overflow-hidden"
                        alt="hotel-crystal-park-chennai-statue"
                      />
                      <img
                        src="/centralRailway.webp"
                        className="w-full h-full "
                        alt="hotel-crystal-park-chennai-centralRailway"
                      />
                    </div>

                    <div className="relative w-8/12">
                      <img
                        src="/riponBuilding.webp"
                        alt="hotel-crystal-park-chennai-riponBuilding"
                        className="absolute w-full h-full"
                      ></img>
                      <div className="absolute inset-0 bg-black opacity-15 sm:opacity-25"></div>
                      <div className="flex flex-col gap-3 justify-center p-3 w-full h-full backdrop-blur-[1px] z-10 ">
                        <div className="flex flex-col justify-center items-center">
                          <img
                            src="/logo3.webp"
                            alt="hotel-crystal-park-chennai"
                            className="w-96"
                          ></img>
                          <img
                            src="/logo2.webp"
                            alt="hotel-crystal-park-chennai"
                            className="w-[500px]"
                          ></img>
                        </div>
                        {/* <h1 className="text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] font-bold text-white w-full z-10">
                      Hotel Crystal Park
                    </h1> */}
                        <div className="hidden sm:flex flex-col gap-3 z-10">
                          <p className="font-cinzel italic text-[24px] text-white">
                            Your Home Away from Home
                          </p>
                          <div className="flex flex-co justify-center gap-2 w-full items-center">
                            <Link
                              to="targetDiv"
                              // smooth={true}
                              duration={500}
                              className="bg-white text-black px-6 py-2 rounded hover:bg-opacity-80 cursor-pointer transition-all"
                              // onClick={scrollToDiv}
                            >
                              BoOk your stay
                            </Link>
                            <Link
                              to="footerDiv"
                              // smooth={true}
                              duration={500}
                              className="bg-white text-black px-6 py-2 rounded hover:bg-opacity-80 cursor-pointer transition-all"
                              // onClick={scrollToDiv}
                            >
                              Contact Us
                            </Link>
                          </div>

                          <div className="flex items-center justify-center">
                            <div className="text-white bg-white bg-opacity-40 backdrop-blur-md p-3 rounded-lg ">
                              {/* <span className="font-semibold text-[14px] sm:text-[16px]">
                            Prime Locations Nearby
                          </span> */}
                              <div className="flex flex-col font-serif items-center text-[14px] sm:text-[18px]">
                                <Link
                                  to="locationDiv"
                                  className="flex gap-2 items-center cursor-pointer hover:underline transition-all"
                                >
                                  <p>Just Opp. to US Consulate, Chennai</p>
                                  <MdLocationPin
                                    size={20}
                                    className="pulse-pop"
                                  />
                                </Link>

                                <Link
                                  to="locationDiv"
                                  className="flex gap-2 items-center cursor-pointer hover:underline transition-all"
                                >
                                  <p>
                                    US Visa OFC/ VAC for Fingerprint Biometric
                                    (2 km)
                                  </p>
                                  <MdLocationPin
                                    size={20}
                                    className="pulse-pop"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative md:w-1/4 overflow-hidden">
                  <img
                    src="/hotel.webp"
                    alt="hotel-crystal-park-chennai-"
                    className="w-full h-full"
                  ></img>
                  <div className="sm:hidden absolute inset-0 bg-black opacity-20"></div>
                  <div className="sm:hidden flex absolute flex-col items-center gap-3 z-50 w-full h-full top-0 left-0  py-5">
                    <p className="font-cinzel font-semibold italic text-[18px] sm:text-[24px] text-white">
                      Your Home Away from Home
                    </p>
                    <div className="flex flex-co justify-center gap-2 w-full items-center">
                      <Link
                        to="targetDiv"
                        // smooth={true}
                        duration={500}
                        className="bg-white text-[12px] sm:text-[16px] text-black sm:px-6 p-2 rounded hover:bg-opacity-80 cursor-pointer transition-all"
                        // onClick={scrollToDiv}
                      >
                        BoOk your stay
                      </Link>
                      <Link
                        to="footerDiv"
                        // smooth={true}
                        duration={500}
                        className="bg-white text-[12px] sm:text-[16px] text-black sm:px-6 p-2 rounded hover:bg-opacity-80 cursor-pointer transition-all"
                        // onClick={scrollToDiv}
                      >
                        Contact Us
                      </Link>
                    </div>

                    <div className="flex items-center justify-center">
                      <div className="text-white bg-white bg-opacity-40 backdrop-blur-md p-2 sm:p-3 rounded-lg ">
                        {/* <span className="font-semibold text-[14px] sm:text-[16px]">
                            Prime Locations Nearby
                          </span> */}
                        <div className="flex flex-col font-serif items-center text-[12px] sm:text-[18px] text-black">
                          <Link
                            to="locationDiv"
                            className="flex gap-2 items-center cursor-pointer hover:underline transition-all"
                          >
                            <p>Just Opp. to US Consulate, Chennai</p>
                            <MdLocationPin size={20} className="pulse-pop" />
                          </Link>

                          <Link
                            to="locationDiv"
                            className="flex gap-2 items-center cursor-pointer hover:underline transition-all"
                          >
                            <p>
                              US Visa OFC/ VAC for Fingerprint Biometric (2 km)
                            </p>
                            <MdLocationPin size={20} className="pulse-pop" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-32 sm:h-60 bg-gradient-to-b from-transparent to-[#1e4a41] absolute bottom-0 left-0 right-0"></div>
        </div>

        <div className="pb-3 px-2 sm:px-3">
          <Suites />
        </div>
      </div>

      {/* Sticky Section - Second Screen */}
      <div className="relative w-full h-full text-white" id="targetDiv">
        {/* Deluxe */}
        <div
          className="sticky top-0 h-screen w-full p-5 sm:p-20 bg-white bg-no-repeat bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: `url(${deluxe2})` }}
        >
          <div className="absolute inset-0 bg-black opacity-80 -z-10 overflow-hidden"></div>
          <div className="flex flex-col sm:flex-row items-center gap-5 sm:gap-10 h-full w-full">
            <img
              src={deluxe1}
              alt="hotel-crystal-park-chennai-deluxe"
              className="w-[40rem] rounded- border-4"
            ></img>
            <div className="flex flex-col gap-3 sm:p-5 text-[18px]">
              <h1 className="font-bold text-[25px] sm:text-[50px] max-sm:text-center font-cinzel">
                Deluxe ROoms
              </h1>
              <div className="-mt-2">
                <span className="p-1 px-2 rounded-md bg-white bg-opacity-20 font-Metamorphous">
                  ₹ 2,800 <span className="text-slate-300">(+12% GST)</span>
                </span>
              </div>
              <p className="text-[12px] sm:text-[14px] w-full sm:w-96">
                A cozy space with double-sharing options, free Wi-Fi, AC,
                satellite TV, and daily housekeeping.
              </p>
              <div>
                <Rating
                  name="half-rating-read"
                  defaultValue={4.5}
                  precision={0.5}
                  readOnly
                />
                <ul className="flex flex-col gap-2 text-[14px]">
                  <span className="flex gap-2 items-center">
                    {/* <IoWifi size={20} /> */}
                    <PiWifiMediumBold size={20} />
                    Free Wifi
                  </span>
                  <span className="flex gap-2 items-center">
                    <LuBedDouble size={20} />
                    Double Bed / Twin bed
                  </span>
                  {/* <span className="flex gap-2 items-center">
                    <GrUserWorker size={20} />
                    24/7 Room Service
                  </span> */}
                  {/* <span className="flex gap-2 items-center">
                    <MdPeopleOutline size={20} />
                    Single / Double sharing basis
                  </span> */}
                  <span className="flex gap-2 items-center">
                    <FaPersonCirclePlus size={20} />
                    Extra person - ₹ 500 (+GST)
                  </span>
                  <span className="flex gap-2 items-center">
                    <TbAirConditioning size={20} />
                    Air Conditioner
                  </span>
                  <span className="flex gap-2 items-center">
                    <PiCoffeeBold size={20} />
                    Breakfast (Complimentary)
                  </span>
                </ul>
              </div>
              <div>
                <button
                  className="p-2 px-4 text-[14px] sm:text-[16px] rounded-lg bg-white text-black hover:bg-opacity-70 transition-all font-cinzel"
                  onClick={() => navigate("deluxeroom")}
                >
                  BoOk Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Executive */}
        <div
          className="sticky top-0 h-screen w-full p-5 sm:p-20 bg-white bg-no-repeat bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: `url(${executive2})` }}
        >
          <div className="absolute inset-0 bg-black opacity-80 -z-10 overflow-hidden"></div>
          <div className="flex flex-col sm:flex-row  items-center gap-5 sm:ap-10 h-full w-full">
            <img
              src={executive1}
              alt="hotel-crystal-park-chennai-executive"
              className="w-[40rem] rounded- border-4"
            ></img>
            <div className="flex flex-col gap-3 sm:p-5 text-[18px]">
              <h1 className="font-bold text-[25px] sm:text-[50px] max-sm:text-center font-cinzel">
                Executive ROoms
              </h1>
              <div className="-mt-2">
                <span className="p-1 px-2 rounded-md bg-white bg-opacity-20 font-Metamorphous">
                  ₹ 3,200 <span className="text-slate-300">(+12% GST)</span>
                </span>
              </div>
              <p className="text-[12px] sm:text-[14px] w-full sm:w-96">
                A spacious room with options for double or triple-sharing,
                equipped with a work desk, satellite TV, and daily housekeeping.
              </p>
              <div>
                <Rating
                  name="half-rating-read"
                  defaultValue={5}
                  precision={0.5}
                  readOnly
                />
                <ul className="flex flex-col gap-2 text-[14px]">
                  <span className="flex gap-2 items-center">
                    {/* <IoWifi size={20} /> */}
                    <PiWifiMediumBold size={20} />
                    Free Wifi
                  </span>
                  <span className="flex gap-2 items-center">
                    <MdOutlineBedroomParent size={20} />
                    Double / Triple Bed
                  </span>
                  {/* <span className="flex gap-2 items-center">
                    <GrUserWorker size={20} />
                    24/7 Room Service
                  </span> */}
                  {/* <span className="flex gap-2 items-center">
                    <MdPeopleOutline size={20} />
                    Single / Double sharing basis
                  </span> */}
                  <span className="flex gap-2 items-center">
                    <FaPersonCirclePlus size={20} />
                    Extra person - ₹ 500 (+GST)
                  </span>
                  <span className="flex gap-2 items-center">
                    <TbAirConditioning size={20} />
                    Air Conditioner
                  </span>
                  <span className="flex gap-2 items-center">
                    <PiCoffeeBold />
                    Breakfast (Complimentary)
                  </span>
                </ul>
              </div>
              <div>
                <button
                  className="p-2 px-4 text-[14px] sm:text-[16px] rounded-lg bg-white text-black hover:bg-opacity-70 transition-all font-cinzel"
                  onClick={() => navigate("executiveroom")}
                >
                  BoOk Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Suite */}
        <div
          className="sticky top-0 h-screen w-full  p-5 sm:p-20 bg-white bg-no-repeat bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: `url(${suite1})` }}
        >
          <div className="absolute inset-0 bg-black opacity-80 -z-10 overflow-hidden"></div>
          <div className="flex flex-col sm:flex-row items-center gap-5 sm:gap-10 h-full w-full">
            <img
              src={suite1}
              alt="hotel-crystal-park-chennai-suite"
              className="w-[40rem] rounded- border-4"
            ></img>
            <div className="flex flex-col gap-3 sm:p-5 text-[18px]">
              <h1 className="font-bold text-[25px] sm:text-[50px] max-sm:text-center font-cinzel">
                Suite ROoms
              </h1>
              <div className="-mt-2">
                <span className="p-1 px-2 rounded-md bg-white bg-opacity-20 font-Metamorphous">
                  ₹ 3,500 <span className="text-slate-300">(+12% GST)</span>
                </span>
              </div>
              <p className="text-[12px] sm:text-[14px] w-full sm:w-96">
                The ultimate in comfort with triple-sharing options, a
                mini-fridge, iron box, and exclusive amenities.
              </p>
              <div>
                <Rating
                  name="half-rating-read"
                  defaultValue={4}
                  precision={0.5}
                  readOnly
                />
                <ul className="flex flex-col gap-2 text-[14px]">
                  <span className="flex gap-2 items-center">
                    {/* <IoWifi size={20} /> */}
                    <PiWifiMediumBold size={20} />
                    Free Wifi
                  </span>
                  <span className="flex gap-2 items-center">
                    <MdOutlineBedroomParent size={20} />
                    Double / Triple Bed
                  </span>
                  {/* <span className="flex gap-2 items-center">
                    <GrUserWorker size={20} />
                    24/7 Room Service
                  </span> */}
                  {/* <span className="flex gap-2 items-center">
                    <MdPeopleOutline size={20} />
                    Single / Double sharing basis
                  </span> */}
                  <span className="flex gap-2 items-center">
                    <FaPersonCirclePlus size={20} />
                    Extra person - ₹ 500 (+GST)
                  </span>
                  <span className="flex gap-2 items-center">
                    <TbAirConditioning size={20} />
                    Air Conditioner
                  </span>
                  <span className="flex gap-2 items-center">
                    <PiCoffeeBold />
                    Breakfast (Complimentary)
                  </span>
                </ul>
              </div>
              <div>
                <button
                  className="p-2 px-4 text-[14px] sm:text-[16px] rounded-lg bg-white text-black hover:bg-opacity-70 transition-all font-cinzel"
                  onClick={() => navigate("suiteroom")}
                >
                  BoOk Now
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Review */}
        <div className="sticky top-0 bg-[#1e4a41] from-[#7D7463] via-[#a3a3b1f7] to-[#7D7463] z-50 text-black overflow-hidden">
          <div className="flex flex-col  gap-10 p-3 sm:p-5">
            <div className="flex flex-col gap-5 sm:gap-16 justify-center items-center w-full text-white">
              <span
                className="font-Metamorphous font-bold text-[30px] sm:text-[70px] text-center rounded-lg p-3 sm:px-5 bg-white bg-opacity-15 text-trans text"
                style={{
                  color: "transparent",
                  textShadow: "2px 2px 2px white",
                }}
              >
                Welcome to Hotel Crystal Park
              </span>
              <div className="flex flex-col">
                <p className="sm:px-10 p-2 text-md sm:text-xl italic font-forum font-100">
                  Welcome to Hotel Crystal Park, your serene haven in the heart
                  of the city. Perfectly suited for both Visa and Hospital and
                  Business travelers and Tourists, our hotel offers a seamless
                  blend of comfort, convenience, and elegance. With easy access
                  to major attractions, shopping hubs, business centers, and
                  visa offices, we ensure that everything you need is just
                  minutes away, making your stay ideal for leisure, work, or
                  visa-related purposes.
                </p>

                {/* <p className="px-10 p-2 text-xl italic font-forum font-100">
                  Prime Location in Erode Located on Perundurai Road, our hotel
                  sits amidst a vibrant neighborhood with a range of dining,
                  shopping, and entertainment options close by. Hotel Crystal
                  Park offers guests an excellent base from which to explore
                  Erode's cultural and commercial highlights. The railway
                  station is just a 10-minute drive away, and the hotel is
                  well-connected to nearby highways, making it convenient for
                  guests traveling by road.
                </p> */}
              </div>
            </div>
            <div className="flex flex-col gap-10 p-5 sm:p-10 border-x-2 border-b-2 rounded-md bg-white bg-opacity-30 text-black">
              <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 ">
                Property Overview
              </h2>
              <PropertyOverview />
            </div>
            <div className="flex flex-col gap-10 w-full p-3 sm:p-10 border-x-2 border-b-2 rounded-md text-black bg-white bg-opacity-30">
              <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans text-slate-900">
                Our Hospitality Standards
              </h2>
              <FeaturesGrid featureList={featureList} />
            </div>
            <ReviewCarousel />
          </div>
          <div className="p-3 sm:p-5" id="locationDiv">
            <LocationGrid />
          </div>
        </div>
      </div>

      <div id="footerDiv">
        <Footer />
      </div>
    </div>
  );
}
