import React, { useRef } from "react";
import { CopyCheck } from "lucide-react";

function SuccessModal({ closeModal, text }) {
  // const modalRef = useRef();
  // const bgModal = (e) => {
  //   if (modalRef.current == e.target) {
  //     closeModal();
  //   }
  // };
  return (
    <div
      className="fixed top-0 left-[calc(50vw-7rem)] sm:left-[calc(50vw-5rem)] transform -translate-x-10 p-5  flex flex-col items-center gap-5 bg-gradient-to-b from-[#1e4a41e6] via-[#5e9986ba] to-[#1e4a41e6] rounded-xl"
      data-aos="fade-down"
    >
      <CopyCheck size={50} className="text-green-500 " />
      <p className="text-center text-[12px] sm:text-[16px] font-forum">{text}</p>
    </div>
  );
}

export default SuccessModal;
