import React, { useEffect, useRef, useState } from "react";

import img1 from "../assets/hotel/1.webp";
import img2 from "../assets/hotel/2.webp";
import img3 from "../assets/hotel/3.webp";
import img4 from "../assets/hotel/4.webp";
import img5 from "../assets/hotel/5.webp";
import img6 from "../assets/hotel/6.webp";
import img7 from "../assets/hotel/7.webp";
import img8 from "../assets/hotel/8.webp";
import img9 from "../assets/hotel/9.webp";

function Suites() {
  const scrollContainerRef = useRef(null);
  const [images, setImages] = useState([
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
  ]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let animationFrameId;
    let scrollPosition = 0;

    const autoScroll = () => {
      if (scrollContainer) {
        // Increment scroll position
        scrollPosition += 1;

        // Reset scroll position when it reaches the total width of images
        if (scrollPosition >= scrollContainer.scrollWidth / 2) {
          scrollPosition = 0;
        }

        // Perform the scroll
        scrollContainer.scrollLeft = scrollPosition;

        // Continue the animation
        animationFrameId = requestAnimationFrame(autoScroll);
      }
    };

    // Start auto-scrolling
    animationFrameId = requestAnimationFrame(autoScroll);

    // Cleanup function to stop scrolling when component unmounts
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <div className="relative">
      <div
        ref={scrollContainerRef}
        className="flex space-x-3 sm:space-x-10 overflow-x-hidden overflow-y-hidden scrollbar-hide"
      >
        {images.map((img, index) => (
          <div
            key={index}
            className="flex-none w-[200px] sm:w-[350px] transform-gpu hover:scale-105 transition-transform duration-300"
          >
            <img
              className="w-full h-[150px] sm:h-[200px] object-cover rounded-lg cursor-pointer"
              alt={`poster ${index + 1}`}
              src={img}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Suites;
