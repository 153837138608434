import { UserRound } from "lucide-react";


import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../App.css";

const reviewList = [
  {
    name: "Aishwarya Malik",
    title: "Best Hotel",
    description:
      "The rooms are maintained neatly and are very comfortable. Housekeeping staff are extremely courteous and ensure everything is perfect. The hotel has a very convenient location, with easy access to local stores, markets, and schools, making it ideal for both tourists and business travelers alike. I was very impressed by the overall ambiance and service quality here.",
    rating: "4.5",
  },
  {
    name: "Manoj Kumar",
    title: "Nice Rooms",
    description:
      "The garden view from the room was simply mesmerizing, offering a peaceful escape right outside my window. The natural light fills the room beautifully during the day, creating a warm and welcoming environment. This hotel also offers quick access to essential stores, markets, and other facilities. A truly enjoyable experience overall, with polite staff and well-maintained amenities.",
    rating: "4.1",
  },
  {
    name: "Priya Singh",
    title: "Comfortable Stay",
    description:
      "I had an excellent stay at this hotel. The complimentary breakfast had a wide range of options that catered to all tastes, and it was absolutely delicious. The room was spacious and impeccably clean, and the amenities provided made my stay very comfortable. The staff was exceptionally warm and welcoming, always eager to help. Overall, it was a great experience, and I look forward to coming back.",
    rating: "4.7",
  },
  {
    name: "Ravi Sharma",
    title: "Amazing Experience",
    description:
      "From the moment I checked in, I felt welcomed and valued as a guest. The staff went above and beyond to accommodate my requests, and the location of the hotel is ideal, close to many attractions and points of interest. The room was tastefully decorated, creating a calming atmosphere, and every aspect of my stay exceeded my expectations. I highly recommend this hotel to anyone visiting the area!",
    rating: "5.0",
  },
  {
    name: "Megha Patel",
    title: "Good Value for Money",
    description:
      "I was pleasantly surprised by the quality of service and amenities at this price point. The rooms were spacious, modern, and impeccably clean. The amenities, such as free Wi-Fi and 24-hour hot water, were very convenient. I also appreciated the daily housekeeping, which kept the room spotless. It's definitely a good choice for anyone looking for quality at a reasonable rate.",
    rating: "4.3",
  },
  {
    name: "Vikas Joshi",
    title: "Pleasant Stay",
    description:
      "I thoroughly enjoyed my stay here. The hotel is ideally situated near several shopping centers, which made it easy to explore the area. The staff was very attentive, responding quickly to any requests and always with a friendly smile. The room was comfortable, and the ambiance of the hotel was peaceful, making it a perfect place to relax. I would definitely stay here again.",
    rating: "4.2",
  },
  {
    name: "Neha Kapoor",
    title: "Great Ambience",
    description:
      "The hotel has a charming atmosphere, with tasteful decor and an inviting layout. The lobby, in particular, is a beautiful space to unwind or meet friends. My room was spacious, well-maintained, and filled with thoughtful amenities. The location made it easy to explore the surrounding area, and the staff was incredibly kind and helpful. I would highly recommend it to anyone visiting!",
    rating: "4.8",
  },
  {
    name: "Amit Gupta",
    title: "Exceptional Service",
    description:
      "The staff here truly made my stay memorable. They were always eager to help with anything I needed, from restaurant recommendations to arranging transportation. The room was well-kept and cozy, and I felt at home during my entire stay. It's rare to find such genuine hospitality, and I would highly recommend this hotel to anyone.",
    rating: "4.6",
  },
  {
    name: "Sneha Rao",
    title: "Peaceful and Relaxing",
    description:
      "This hotel provided a much-needed escape from the busy city life. The quiet surroundings and comfortable rooms allowed me to unwind completely. The staff was very attentive and always checked in to see if I needed anything. I particularly enjoyed the spacious, well-furnished rooms and the high standard of cleanliness maintained throughout my stay.",
    rating: "4.4",
  },
  {
    name: "Rahul Verma",
    title: "Perfect Location",
    description:
      "The location of this hotel was incredibly convenient, allowing easy access to popular attractions and dining options. The room itself was stylish and cozy, equipped with all the essentials and more. I especially appreciated the complementary breakfast, which offered a variety of delicious options. Overall, I felt very comfortable and at home here. Would highly recommend!",
    rating: "4.9",
  },
  {
    name: "Sonal Mishra",
    title: "Highly Recommended",
    description:
      "This hotel exceeded all my expectations. The rooms were spotlessly clean, spacious, and comfortable. The staff was courteous and went out of their way to ensure that my stay was enjoyable. The overall vibe of the place was warm and welcoming, and I felt like I was in a home away from home. It’s a great choice if you're looking for a peaceful and hassle-free stay.",
    rating: "5.0",
  },
];

export default function ReviewGrid() {
  const settings = {
    infinite: true, // Enables infinite scrolling
    centerMode: true, // Centers the active slide
    slidesToShow: 3, // Shows 3 slides at once
    slidesToScroll: 1, // Scrolls 1 slide at a time
    autoplay: true, // Auto-scroll the carousel
    autoplaySpeed: 2000, // Interval between slides in ms (3 seconds)
    speed: 500, // Transition speed
    dots: true, // Shows dots for navigation
    focusOnSelect: true, // Ensures the selected slide is centered
    responsive: [
      {
        breakpoint: 1170, // For large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For medium screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0, // For small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col gap-10 p-3 sm:p-10 border-x-2 border-b-2 rounded-md bg-white bg-opacity-30">
      <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans text-black">
        Ratings & Reviews
      </h2>

      {/* <div
          className="flex gap-10 overflow-hidden w-full"
          ref={containerRef}
          style={{ transition: "transform 0.5s ease-in-out" }}
          > */}
      <div className="testimonial_section cursor-point cursor-grab">
        <Slider
          {...settings}
          className="gap-5 testimonial-owl-carousel owl-carousel"
        >
          {reviewList.map((item, index) => (
            <div className="testimonial_card p-2 sm:p-5 w-[400px]" key={index}>
              <div className="header flex flex-col sm:flex-row justify-between">
                <div className="flex gap-2">
                  <div className="flex items-center justify-center h-full">
                    <UserRound
                      className="bg-slate-500 rounded-full p-2 sm:p-2"
                      size="35px"
                    />
                  </div>
                  <div>
                    <span className="font-700 text-[18px] sm:text-[22px]">
                      {item.name}
                    </span>
                  </div>
                </div>
                <div className="flex gap-1 items-center justify-end">
                  <span className="text-[14px] sm:text-[18px] font-700">
                    {item.rating}
                  </span>
                  {/* <Star className="fill-white" /> */}
                  <span className="text-xl sm:text-2xl">⭐</span>
                </div>
              </div>

              <div className="text-slate-900">
                <span className="text-[14px] sm:text-[18px] font-500 font-merriweatherSans">
                  {item.title}
                </span>
                <p className="text-[14px] sm:text-[18px] testimonial_txt italic">
                  " {item.description} "
                </p>
              </div>
            </div>
          ))}
          {/* </div> */}
        </Slider>
      </div>
    </div>
  );
}
