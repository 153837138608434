import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-5 bg-[#1e4a41] text-white p-5 md:px-20">
      <div className="flex flex-col md:flex-row gap-10 justify-between">
        <div>
          <img
            src="/logo1.webp"
            alt="hotel-crystal-park-chennai-logo"
            className="w-60 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-5 text-[14px] lg:text-[16px] sm:items-end">
          {/* <div className="flex flex-col gap-1 sm:gap-4 justify-cente">
            <ul className="hover:underline">ABOUT US</ul>
            <ul className="hover:underline">CONTACT US</ul>
            <ul className="hover:underline">FAQ'S</ul>
          </div> */}
          <div className="flex flex-col gap-1 sm:gap-4 w-80">
            <div className="text-[12px] sm:text-[18px]">
              <h1 className="text-[18px] sm:text-[24px]">Address</h1>
              <p>
                51/22, Model School Road, Anna Salai, Thousand Lights, (Opp. US
                Consulate). Chennai - 600006
              </p>
            </div>
          </div>

          <div className="text-[14px] sm:text-[18px]">
            <h1 className="text-[18px] md:text-[24px]">Contact Us</h1>
            <p>Ph: +91 44 28291305 / 06</p>
            <p>Mob : 75501 25887</p>
            <p>Email: chennaicrystalpark@gmail.com</p>
            <p>Web: www.hotelcrystalpark.in</p>
            <p>GSTIN: 33AAOFC7839D1ZC</p>
          </div>
        </div>
      </div>

      <div className="divider bg-white bg-opacity-50 h-[0.5px]"></div>

      <div className="text-[11px] sm:text-[12px] text-center text-white text-opacity-70">
        <p>© 2024 Hotel Crystal Park. All rights reserved.</p>
      </div>
      {/* <div className="flex flex-col sm:flex-row gap-5 justify-between">
      </div> */}
    </div>
  );
}
