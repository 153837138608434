import { Routes, Route } from "react-router-dom";
import "./App.css";

import Homepage from "./pages/homepage";
import SuiteRoom from "./pages/suiteRoom";
import ExecutiveRoom from "./pages/executiveRoom";
import DeluxeRoom from "./pages/deluxeRoom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/SuiteRoom" element={<SuiteRoom />} />
      <Route path="/ExecutiveRoom" element={<ExecutiveRoom />} />
      <Route path="/DeluxeRoom" element={<DeluxeRoom />} />
    </Routes>
  );
}

export default App;
