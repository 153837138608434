import React from "react";

export default function AmenitiesGrid({ AmenityList }) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 sm:gap-5">
      {Object.entries(AmenityList)?.map((items, index) => (
        <div className="flex flex-col items-center text-center" key={index}>
          <div className="w-10 h-10 overflow-hidden p-2">{items[1]}</div>
          <div>
            <p className="text-[14px] text-white font-forum ">{items[0]}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
