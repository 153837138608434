import React from "react";

import { BiCctv, BiSolidCarGarage } from "react-icons/bi";
import { GiFirstAidKit } from "react-icons/gi";
import { FaFireExtinguisher } from "react-icons/fa";
import { MdPhoneInTalk } from "react-icons/md";
import { GrElevator, GrUserWorker } from "react-icons/gr";
import { IoWifi } from "react-icons/io5";
import { HiOutlineTicket } from "react-icons/hi";
import { PiCarBattery } from "react-icons/pi";
import { IoMdPrint } from "react-icons/io";

export default function PropertyOverview() {
  const HotelAmenity = {
    // "24-hour Room Service": <BiCctv size={30} />,
    "Power Backup": <PiCarBattery size={30} />,
    lift: <GrElevator size={30} />,
    Telephone: <MdPhoneInTalk size={30} />,
    Housekeeping: <GrUserWorker size={30} />,
    "Parking (depending on availability)": <BiSolidCarGarage size={30} />,
    "Free Wi-Fi": <IoWifi size={30} />,
    CCTV: <BiCctv size={30} />,
    "Fire Extinguishers": <FaFireExtinguisher size={30} />,
    "First-aid Box": <GiFirstAidKit size={30} />,
    // "Doctor on Call": <FaUserDoctor size={30} />,
    // "Wheelchair accessible": <FaWheelchair size={30} />,
    "Ticket/Tour Assistance": <HiOutlineTicket size={30} />,
    // "Mail Services": <FaMailBulk size={30} />,
    "Printing / Scanning (Chargeable)": <IoMdPrint size={30} />,
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 text-center">
      {Object.entries(HotelAmenity)?.map((items, index) => (
        <div className="flex flex-col items-center text-center" key={index}>
          <div className="w-10 h-10 overflow-hidden p-2">{items[1]}</div>
          <div>
            {/* <span className="text-[16px] font-500"></span> */}
            <p className="text-[14px] text-slate-800">{items[0]}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
