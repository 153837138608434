import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// Components
import ImageGrid from "../components/imageGrid";
import AmenitiesGrid from "../components/amenitiesGrid";
import PricingForm from "../components/pricingForm";
import Footer from "../components/footer";
import FeaturesGrid from "../components/featuresGrid";

// Images
import img1 from "../assets/executive/1.webp";
import img2 from "../assets/executive/2.webp";
import img3 from "../assets/executive/3.webp";
import img4 from "../assets/executive/4.webp";
import img5 from "../assets/executive/5.webp";
import img6 from "../assets/executive/6.webp";
import img7 from "../assets/executive/7.webp";
import img8 from "../assets/executive/8.webp";
import img9 from "../assets/executive/9.webp";
import img10 from "../assets/executive/10.webp";
import img11 from "../assets/executive/11.webp";
import img12 from "../assets/executive/12.webp";
import img13 from "../assets/executive/13.webp";
import img14 from "../assets/executive/14.webp";
import img15 from "../assets/executive/15.webp";
import img16 from "../assets/executive/16.webp";
import img17 from "../assets/executive/17.webp";
import img18 from "../assets/executive/18.webp";
import img19 from "../assets/executive/19.webp";
import img20 from "../assets/executive/20.webp";
import img21 from "../assets/executive/21.webp";
import img22 from "../assets/executive/22.webp";
import img23 from "../assets/executive/23.webp";
import img24 from "../assets/executive/24.webp";

// Icons
import { PiCarBattery } from "react-icons/pi";
import { MdPhoneInTalk } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { GiTv } from "react-icons/gi";
import { TbAirConditioning } from "react-icons/tb";
import { IoWifi } from "react-icons/io5";

const ExecutiveRoom = () => {
  const navigate = useNavigate();

  const imageList = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RoomAmenity = {
    // "24-hour Room Service": <TbClock24  size={30} />,
    "Power Backup": <PiCarBattery size={30} />,
    Telephone: <MdPhoneInTalk size={30} />,
    Housekeeping: <GrUserWorker size={30} />,
    "Air Conditioning": <TbAirConditioning size={30} />,
    "Free Wi-Fi": <IoWifi size={30} />,
    "Satellite TV": <GiTv size={30} />,
    // Balcony: <MdBalcony size={30} />,
    // Heater: <GiHeatHaze size={30} />,
  };

  const featureList = {
    Popular: [
      "24-hour HouseKeeping",
      // "Laundry Service",
      "Free Wi-Fi",
      // "Bathroom",
      "Air Conditioner",
      "Mineral Water - additional charge",
    ],
    Availabilities: [
      "Charging Points",
      "Mirror",
      // "Closet",
      "Hangers",
      // "Seating Area",
      // "Sofa",
      // "Blackout Curtains",
      // "Mineral Water - additional charge",
      "Telephone",
      "Flooring",
    ],
    "Beds and Blankets": ["Pillows", "Woollen Blanket"],
    // Bathroom: [
    //   "Shaving Mirror",
    //   "Geyser/Water Heater",
    //   "Sanitary Bin",
    //   "Western Toilet Seat",
    //   "Dustbins",
    //   "Toilet Paper",
    //   "Shower",
    //   "Towels",
    //   "Soap & Shampoo"
    // ],
    Others: ["Sanitizers", "Glasses", "TV"],
  };

  return (
    <div className="h-full w- overflow-x-hidden text-white">
      <div className="fixed -z-10 h-screen w-screen bg-gradient-to-br from-[#1e4a41] via-[#6a8a83] to-[#1e4a41]"></div>
      {/* <Header customClass="bg-[#FCF8F4]" /> */}

      <div className="flex flex-col p-3 sm:p-5 gap-10 sm:px-8 md:px-14 h-full w-full font-inter">
        {/* Back arrow */}
        <div>
          <ArrowLeft
            size={30}
            onClick={() => navigate(-1)}
            className="cursor-pointer border  rounded-full p-1  hover:bg-white hover:bg-opacity-20 transition-all"
          />
        </div>

        {/* Section 1 (Property Details) */}
        <div className="flex flex-col gap-5 lg:gap-10 w-full">
          {/* Upper */}
          <div className="flex flex-col lg:flex-row gap-10 w-full">
            {/* Property Preview */}
            <div className="w-full lg:w-2/3">
              {/* Title */}
              <h1 className="text-[20px] sm:text-[32px] font-600 font-cinzel mb-4">
                Executive RoOms
              </h1>
              {/* Address */}
              {/* <div className="address flex justify-between p-2">
                <span className="flex items-center font-300 text-[12px] sm:text-[16px]">
                  <MapPin className="mr-1" />
                  {localStorage.getItem("locality") || "Chandra Nagar"}, near{" "}
                  {localStorage.getItem("landmark") || "Shivaji Temple"},{" "}
                  {localStorage.getItem("city") || "Lucknow"}
                </span>
                <div className="flex gap-2">
                  <div className="flex items-center shadow-2xl shadow-black rounded-full cursor-pointer p-1 sm:p-2">
                    <Heart />
                  </div>
                  <div className="flex items-center shadow-2xl shadow-black rounded-full cursor-pointer p-1 sm:p-2">
                    <Upload />
                  </div>
                </div>
              </div> */}

              {/* Images */}
              <ImageGrid imageList={imageList} />
            </div>

            {/* Right (Pricing section) */}
            <PricingForm rate={3200} roomType="Executive" />
          </div>

          {/* Description */}
          <div className="flex flex-col gap-5 sm:gap-10 p-5 sm:p-10 border-x-2 border-b-2 rounded-md bg-[#10312a] bg-opacity-50 shadow-lg">
            <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans text-slate-200">
              Decription
            </h2>
            <p className="text-[12px] sm:text-[20px] font-forum">
              Ideal for families or small groups, the Executive Room offers
              flexible sleeping arrangements with double or triple beds, which
              may be single or separate cots. The room includes air
              conditioning, Wi-Fi, an iron box, and a desk with a chair for
              added convenience. Guests can enjoy 24-hour hot water, CCTV
              security, satellite TV, and daily housekeeping. Additional
              amenities include welcome water bottles, soap, shampoo, and fresh
              towels every day.
            </p>
          </div>

          <div className="flex justify-center gap-5 w-full">
            {/* Amenities */}
            <div className="flex flex-col gap-10 w-full p-5 sm:p-10 border-x-2 border-b-2 rounded-md text-slate-200 bg-[#10312a] bg-opacity-40">
              <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans">
                Amenities
              </h2>
              <AmenitiesGrid AmenityList={RoomAmenity} />
            </div>

            {/* Property Overview */}
            {/* <div className="flex flex-col gap-10 p-5 sm:p-10 border-x-2 border-b-2 rounded-md">
              <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans">
                Property Overview
              </h2>
              <PropertyOverview />
            </div> */}
          </div>

          <div className="flex flex-col gap-10 w-full p-5 sm:p-10 border-x-2 border-b-2 rounded-md text-white bg-[#10312a] bg-opacity-50">
            <h2 className="text-[20px] sm:text-[32px] max-sm:text-center font-500 font-merriweatherSans">
              Room Features
            </h2>
            <FeaturesGrid featureList={featureList} />
          </div>
        </div>

        {/* Ratings & Review (Section 3) */}

        {/* <LocationGrid /> */}
        {/* About Miraroad (Section 5) */}
        {/* <AboutSection /> */}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ExecutiveRoom;
