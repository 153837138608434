import { CircleArrowLeft, CircleArrowRight } from "lucide-react";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const ImageGrid = ({ imageList }) => {
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  if (!imageList || imageList.length === 0) {
    return null;
  }

  const mainImage = imageList[0];
  const sideImages = imageList.slice(1, 6);
  const hasMoreImages = imageList.length > 7;

  const openMainModal = () => setIsMainModalOpen(true);
  const closeMainModal = () => setIsMainModalOpen(false);

  const openImageModal = (image) => {
    setSelectedImageIndex(image);
    setIsImageModalOpen(true);
  };
  const closeImageModal = () => setIsImageModalOpen(false);

  const nextImage = () => {
    console.log("imageList: ", imageList);

    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };

  const prevImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length
    );
  };

  return (
    <div className="grid grid-cols-8 grid-rows-6 gap-1 max-h-[30rem]">
      {/* Main image container */}
      <div className="relative row-span-4 col-span-6 w-full overflow-hidden rounded-lg cursor-pointer group">
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 z-10 overflow-hidden transition-all"></div>

        <img
          src={mainImage}
          alt="hotel-crystal-park-chennai-Main"
          className="w-full h-full object-cover group-hover:scale-[1.01] transition-all"
          onClick={() => openImageModal(0)}
        />
      </div>

      {/* Side image containers */}
      {sideImages.map((image, index) => (
        <div
          key={index}
          className="relative w-full overflow-hidden rounded-lg row-span-2 col-span-2 cursor-pointer group"
          onClick={() => openImageModal(index + 1)}
        >
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 z-10 overflow-hidden transition-all"></div>

          <img
            src={image}
            alt={`Image ${index + 2}`}
            className="w-full h-full object-cover  group-hover:scale-105 transition-all transform"
          />
        </div>
      ))}

      {/* Show More button */}
      {hasMoreImages && (
        <div
          className="relative flex items-center justify-center bg-gray-200 rounded-lg text-center row-span-2 col-span-2 cursor-pointer"
          onClick={openMainModal}
        >
          <img
            src={imageList[6] || "imageSrcLink"}
            alt="hotel-crystal-park-chennai-show-more"
            className="w-full h-full object-cover rounded-lg  hover:scale-105 transition-all hover:opacity-50"
          />
          <div className="bg-black absolute w-full h-full z-10 opacity-70 rounded-lg"></div>
          <button className="absolute text-slate-300 font-semibold z-20">
            Show More
          </button>
        </div>
      )}

      {/* Main Modal */}
      {isMainModalOpen && (
        <div style={modalStyles.overlay} data-aos="fade-in">
          <div style={modalStyles.modal}>
            <button className="absolute top-0 right-0" onClick={closeMainModal}>
              <IoCloseCircleOutline size={30} />
            </button>
            <div className="grid grid-cols-5 gap-4 p-4">
              {imageList.map((image, index) => (
                <div
                  key={index}
                  className="w-full h-48 overflow-hidden rounded-lg cursor-pointer"
                  onClick={() => openImageModal(index)}
                >
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Image Modal for Full-Screen View */}
      {isImageModalOpen && (
        <div style={modalStyles.overlay} data-aos="fade-in">
          <div style={modalStyles.imageModal}>
            <button
              className="absolute top-0 right-0"
              onClick={closeImageModal}
            >
              <IoCloseCircleOutline size={30} />
            </button>
            <button
              className={`absolute left-4 text-white ${
                selectedImageIndex === 0 ? "hidden " : ""
              }`}
              onClick={prevImage}
              disabled={selectedImageIndex === 0} // Disable if it's the first image
            >
              <CircleArrowLeft />
            </button>
            <img
              src={imageList[selectedImageIndex]}
              alt="hotel-crystal-park-chennai-Selected"
              className="max-w-full max-h-full object-contain" // Ensure the image fits in the modal
            />
            <button
              className="absolute right-4 text-white"
              onClick={nextImage}
              disabled={selectedImageIndex === imageList.length - 1} // Disable if it's the last image
            >
              <CircleArrowRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Define the modal styles for both the main modal and the image modal
const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)", // 80% opacity overlay
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    // padding: "10px",
  },
  modal: {
    backgroundColor: "transparent", // Dark background for main modal
    borderRadius: "8px",
    width: "100vw",
    maxHeight: "90vh",
    overflow: "auto",
    padding: "10px",
    position: "relative",
  },
  imageModal: {
    backgroundColor: "transparent", // Dark background for image modal
    borderRadius: "8px",
    width: "90vw", // Ensure it fits within the viewport width
    height: "90vh", // Ensure it fits within the viewport height
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute", // Fixed to the viewport
    top: "10px",
    right: "10px",
    background: "white",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "1.5rem",
    zIndex: 1001, // Ensure it stays above modal content
  },
};

export default ImageGrid;
