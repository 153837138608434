import React, { useState } from "react";
import Mailgun from "mailgun.js";
import formData from "form-data";

import SuccessModal from "../modals/successModal";
import FailedModal from "../modals/failedModal";

export default function PricingForm({ rate, roomType }) {
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isFailedModal, setIsFailedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [successModalText, setSuccessModalText] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  // const [noOfDays, setNoOfDays] = useState("");
  const [adults, setAdults] = useState("1");
  const [children, setChildren] = useState("0");

  // const DOMAIN = "sandboxd98b743180d74c179b1a10f338d1e370.mailgun.org";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (phoneNo.length < 10) {
    //   alert("Enter valid Phone Number.");
    //   return;
    // }

    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email) == false) {
        alert("Enter correct email id.");
        return;
      }
    }
    // setNoOfDays(calculateDaysDifference());
    // console.log("started...");
    setIsLoading(true);

    const mailgun = new Mailgun(formData);
    const mg = mailgun.client({
      username: "api",
      key: "deb5d97192b7e9d929d8da9a785506fb-72e4a3d5-9a606b1a",
    });

    try {
      // console.log("trying...");

      const response = await mg.messages
        .create("sandboxd98b743180d74c179b1a10f338d1e370.mailgun.org", {
          from: "Hotel Crystal Park <hotelcrystalpark@gmail.com>",
          to: ["ponnan2112@gmail.com", "chennaicrystalpark@gmail.com"],
          subject: "New Customer Booking",
          text: "Testing some Mailgun awesomness!",
          html: `<h2>Customer details</h2><p>Name: ${fullName}</p><p>Email: ${
            email || "NA"
          }</p><p>PhoneNo: ${phoneNo}</p><p>Chech-in Date: ${checkinDate}</p><p>Check-out Date: ${checkoutDate}</p><p>No. of Days: ${calculateDaysDifference()}</p><p>Adults: ${adults}</p><p>Children: ${children}</p><p>RoomType: ${roomType}</p>`,
        })
        .then((msg) => {
          // console.log(msg);
          if (msg.status == 200) {
            console.log("Status: ", msg.status);
            // setSuccessModalText("Email sent successfully... ");
            setIsSuccessModal(true);
            setFullName("");
            setEmail("");
            setPhoneNo("");
            setAdults("");
            setChildren("");
            setCheckinDate(null);
            setCheckoutDate(null);
            // setNoOfDays("");
            setTimeout(() => {
              setIsSuccessModal(false);
            }, 5000);
          }
          setIsLoading(false);
        }) // logs response data
        .catch((err) => {
          setIsFailedModal(true);
          setTimeout(() => {
            setIsFailedModal(false);
          }, 3000);
          console.error(err);
        }); // logs any error

      // console.log("Response: ", response);
    } catch (error) {
      console.log("Error occurred: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneNoInput = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");

    setPhoneNo(numericValue.slice(0, 10));
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  // Calculate the difference in days
  const calculateDaysDifference = () => {
    if (!checkinDate || !checkoutDate) return 0;

    const checkin = new Date(checkinDate);
    const checkout = new Date(checkoutDate);

    // Difference in time (milliseconds)
    const differenceInTime = checkout - checkin;

    // Convert milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    return differenceInDays > 0 ? differenceInDays : 0; // Ensure no negative days
  };

  return (
    <div className="w-full lg:w-1/3">
      <div className="flex justify-between xl:px-10 p-5 bg-gradient-to-r from-[#1e4a41e6] to-[#6d9990c9] text-center text-black rounded-t-lg">
        <div className="flex flex-col items-start">
          <span className="text-[18px] sm:text-[24px] font-700 font-merriweatherSans">
            Price per day:
          </span>
          {/* <span className="text-[12px] sm:text-[14px] text-black">
            ( Book with just 25% Payment)
          </span> */}
        </div>
        <div className="flex flex-col">
          <span className="text-[18px] sm:text-[24px] font-700 font-merriweatherSans">
            ₹{rate}
          </span>
          <span className="text-[12px] sm:text-[14px] text-slate-900">
            (+12% GST)
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-2 bg-[#FDFAF7] p-2 sm:p-5 rounded-b-lg text-black">
        {/* <span className="text-[18px] font-600 font-merriweatherSans">
          Want to book this now?
        </span> */}
        {/* <p className="text-[12px] font-300 text-slate-600">
          Contact Person : Melvin Lasrado
        </p> */}
        <form
          className="flex flex-col gap-3 text-[12px] sm:text-[16px]"
          onSubmit={handleSubmit}
        >
          {/* <div className="flex flex-col gap-4 text-[12px] sm:text-[16px]"> */}
          <div className="">
            <label className="text-[14px]">Name *</label>
            <input
              type="text"
              placeholder="Alex"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="p-2 bg-white border-2 w-full"
              required
            />
          </div>
          {/* <div className="flex items-center gap-2 p-2 bg-white border-2">
            <input placeholder="+91-9999999999" className="outline-none" />
            <span className="font-600"></span>
          </div> */}
          <div className="flex justify-between gap-2">
            <div className="">
              <label className="text-[14px]">
                Email{" "}
                <span className="text-[12px] text-slate-500">(optional)</span>
              </label>
              <input
                type="email"
                placeholder="abc@xyz.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-2 bg-white border-2 w-full"
                // required
              />
            </div>
            <div className="">
              <label className="text-[14px]">Phone No *</label>
              <input
                type="text"
                placeholder="000-000-0000"
                required
                value={phoneNo}
                onChange={handlePhoneNoInput}
                className="p-2 bg-white border-2 w-full"
              />
            </div>
          </div>
          <div className="flex justify-between gap-2">
            <div className="">
              <label className="text-[14px]">Check-in Date*</label>
              <input
                type="date"
                required
                value={checkinDate}
                min={today} // Only allow dates from today onwards
                onChange={(e) => setCheckinDate(e.target.value)}
                className="p-2 bg-white border-2 w-full"
              />
            </div>
            <div className="">
              <label className="text-[14px]">Check-out Date *</label>
              <input
                type="date"
                required
                value={checkoutDate}
                min={checkinDate || today}
                onChange={(e) => setCheckoutDate(e.target.value)}
                className="p-2 bg-white border-2 w-full"
              />
            </div>
          </div>
          <div className="flex flex-col gap-3 justify-center w-full border-2 bg-black bg-opacity-10 p-1 -mt-1">
            <p className="p- bg-whte border- w-full text-centr">
              No. of days:{" "}
              <span className="font-sans rounded-sm p-[3px] px-2 whitespace-nowrap">
                {calculateDaysDifference()}
              </span>
            </p>
          </div>
          <div className="flex justify-between gap-2">
            <div className="w-full">
              <label className="text-[14px]">Adults *</label>
              <select
                type="text"
                // placeholder="000-000-0000"
                required
                value={adults}
                onChange={(e) => setAdults(e.target.value)}
                className="p-2 bg-white border-2 w-full"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>

            <div className="w-full">
              <label className="text-[14px]">Children *</label>
              <select
                type="text"
                // placeholder="000-000-0000"
                required
                value={children}
                onChange={(e) => setChildren(e.target.value)}
                className="p-2 bg-white border-2 w-full"
              >
                <option value="0">0</option>
                <option value="1">1</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-[14px] font-semibold">
              Cancellation Policy:
            </span>
            <div className="flex flex-col gap-3 justify-center w-full border-2 bg-red-500 bg-opacity-20 p-2">
              <p className="p- bg-whte border- w-full text-centr">
                Before 15 days of arrival{" "}
                <span className="font-sans bg-black bg-opacity-10 rounded-sm p-[3px] px-2 whitespace-nowrap">
                  Free Cancellation
                </span>
              </p>
              <p className="p- bg-whte border- w-full text-centr">
                Within 15 days of arrival{" "}
                <span className="font-sans bg-black bg-opacity-10 rounded-sm p-[3px] px-2 whitespace-nowrap">
                  No Cancellation
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 justify-center w-full border-2 bg-red-500 bg-opacity-20 p-2">
            <p className="p- bg-whte border- w-full text-centr">
              Check-in Time:{" "}
              <span className="font-sans bg-black bg-opacity-10 rounded-sm p-[3px] px-2 whitespace-nowrap">
                12pm onwards
              </span>
            </p>
            <p className="p- bg-whte border- w-full text-centr">
              Check-out Time:{" "}
              <span className="font-sans bg-black bg-opacity-10 rounded-sm p-[3px] px-2 whitespace-nowrap">
                11am on Check-out date
              </span>
            </p>
          </div>
          <div className="flex justify-center w-full">
            <p className="p- bg-whie border- w-full text-ceter">
              Extra person:{" "}
              <span className="font-sans text-[14px] bg-black bg-opacity-10 rounded-sm p-1 px-2 w-full whitespace-nowrap">
                Rs. 500 + GST per person /day
              </span>
            </p>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full  text-white py-2 rounded-md font-merriweatherSans transition-all ${
              isLoading
                ? "bg-[#434e4ccc]"
                : "bg-[#26554ce6] hover:bg-[#26554ccf]  "
            }`}
            // onClick={() => handleSubmit()}
          >
            {isLoading ? "Loading..." : "BOOK NOW"}
          </button>
          {/* </div> */}
        </form>
      </div>

      {isSuccessModal && (
        <SuccessModal text="Thank you. We'll contact you shortly." />
      )}

      {isFailedModal && <FailedModal />}
    </div>
  );
}
